import styled from "styled-components";

const Container = styled.button`
  background-color: black;
  height: 50px;
  width: 120px;
  border-radius: 10px;
  color: white;
  border: none;
  font-size: 15px;
  font-family: "Graphik";
  outline: none;
  cursor: pointer;
`;

const Button = ({
  children,
  onClick,
}: {
  children: React.ReactNode;
  onClick?: () => void;
}) => {
  return <Container onClick={onClick}>{children}</Container>;
};

export default Button;
