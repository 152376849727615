import styled from "styled-components";

import { withRouter, RouteComponentProps } from "react-router-dom";

// Components
import Button from "../components/Button";

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: white;
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
  h1 {
    font-size: 40px;
    margin-right: 100px;
    margin-bottom: 0px;
  }
  h3 {
    font-size: 25px;
    margin-top: 5px;
  }
  p {
    margin-top: 5px;
  }
`;

const Root = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;
const IntroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "Graphik";
  color: black;
  max-width: 100%;
`;

const LinksContainer = styled.div`
  display: flex;
  max-width: 100%;
  margin-top: 36px;
  justify-content: space-evenly;
`;

const ProductContainer = styled.div`
  margin-top: 20px;
  font-weight: 600;
  span {
    font-weight: 800;
  }
`;

const Home = ({ history }: RouteComponentProps) => {
  return (
    <Container>
      <Content>
        <Root>
          <IntroWrapper>
            <h3>👋 I'm Rob.</h3>
            <h3>I'm an 18 year old Fullstack Developer.</h3>
            <p>
              I'm primarily a backend engineer building realtime services/APIs
            </p>
            <p> and bridging them to the client through React.</p>
          </IntroWrapper>
          <ProductContainer>
            I've built Software for <span>100,000+</span> users and counting.
          </ProductContainer>
          <LinksContainer>
            <Button
              onClick={() =>
                (window.location.href = "https://github.com/robjmorrissey")
              }
            >
              Work
            </Button>
            <Button
              onClick={() =>
                (window.location.href = "https://twitter.com/robjmorrissey")
              }
            >
              Twitter
            </Button>
          </LinksContainer>
        </Root>
      </Content>
    </Container>
  );
};

export default withRouter(Home);
